import React from "react";
import WebRoutes from "./Routes/WebRoutes";

function App() {
  return (
    <>
      <WebRoutes/>
    </>
  );
}

export default App;
